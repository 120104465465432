import React from 'react';

const LoadingAnimation = () => {
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translateY(-50%)',
    },
    loaderWrapper: {
      position: 'relative',
      width: '120px',
      height: '120px',
    },
    spinner: (size, color, delay) => ({
      position: 'absolute',
      width: `${size}px`,
      height: `${size}px`,
      border: `4px solid #f3f3f3`,
      borderTop: `4px solid ${color}`,
      borderRadius: '50%',
      animation: `spin ${1 + delay}s linear infinite`,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }),
    loadingText: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      marginTop: '140px',
      color: '#666',
      animation: 'pulse 1.5s ease-in-out infinite',
    },
  };

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
      @keyframes spin {
        0% { transform: translate(-50%, -50%) rotate(0deg); }
        100% { transform: translate(-50%, -50%) rotate(360deg); }
      }
      @keyframes pulse {
        0% { opacity: 0.6; }
        50% { opacity: 1; }
        100% { opacity: 0.6; }
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.body.style.overflow = 'auto';
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div style={styles.overlay}>
      <div style={styles.container}>
        <div style={styles.loaderWrapper}>
          <div style={styles.spinner(120, '#3498db', 0)}></div>
          <div style={styles.spinner(100, '#e74c3c', 0.2)}></div>
          <div style={styles.spinner(80, '#f1c40f', 0.4)}></div>
          <div style={styles.spinner(60, '#2ecc71', 0.6)}></div>
          <div style={styles.spinner(40, '#9b59b6', 0.8)}></div>
          <div style={styles.loadingText}>Loading...</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;