import React, { useState, useMemo, lazy } from 'react';
import AnimatedRoutes from './components/AnimatedRoutes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Container, Box } from '@mui/material';
import NavigationBar from './components/NavigationBar';
import TopBar from './components/TopBar';
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider } from './context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const MangaDetail = lazy(() => import('./pages/MangaDetail'));
const Reader = lazy(() => import('./pages/Reader'));
const Favorites = lazy(() => import('./pages/Favorites'));
const History = lazy(() => import('./pages/History'));

function App() {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: '#1976d2',
          },
          secondary: {
            main: '#dc004e',
          },
          background: {
            default: darkMode ? '#121212' : '#ffffff',
            paper: darkMode ? '#1e1e1e' : '#ffffff',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              root: {
                boxShadow: 'none',
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                transition: 'all 0.5s ease-in-out',
              },
            },
          },
        },
      }),
    [darkMode]
  );

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem('darkMode', (!darkMode).toString());
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <AuthProvider>
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          sx={{
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default,
            transition: 'background-color 0.5s ease-in-out',
          }}
        >
          <TopBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          <Container
            maxWidth={false}
            sx={{
              mb: { xs: 8, sm: 10 },
              mt: { xs: 8, sm: 9 },
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              px: { xs: 0, sm: 2 },
            }}
          >
            <AnimatedRoutes
              routes={[
                { path: '/', element: <Home /> },
                { path: '/manga/:title', element: <MangaDetail /> },
                { path: '/read/:chapterUrl', element: <Reader /> },
                { path: '/login', element: <Login /> },
                { path: '/favorites', element: <Favorites />, protected: true },
                { path: '/history', element: <History />, protected: true },
              ]}
            />
          </Container>
          <NavigationBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        </Box>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;