import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HistoryIcon from '@mui/icons-material/History';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function NavigationBar({ darkMode, toggleDarkMode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(location.pathname);

  if (location.pathname.startsWith('/read/')) {
    return null;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const ThemeIcon = () => (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        key={darkMode ? 'dark' : 'light'}
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </motion.div>
    </AnimatePresence>
  );

  return (
    <Box
      component={motion.div}
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}
    >
      <Paper
        sx={{
          background: (theme) =>
            theme.palette.mode === 'dark'
              ? 'rgba(18, 18, 18, 0.9)'
              : 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          transition: 'background-color 0.5s ease-in-out',
        }}
        elevation={3}
      >
        <BottomNavigation
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiBottomNavigationAction-root': {
              transition: 'all 0.3s ease-in-out',
            },
          }}
        >
          <BottomNavigationAction value="/" icon={<HomeIcon />} />
          <BottomNavigationAction value="/favorites" icon={<FavoriteIcon />} />
          <BottomNavigationAction value="/history" icon={<HistoryIcon />} />
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
              toggleDarkMode();
            }}
            icon={<ThemeIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

export default NavigationBar;